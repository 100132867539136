/* src/components/DeliveryStatusCard/DeliveryStatusCard.css */

.delivery-status-card {
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
    color: #333;
    /* 默认灰色背景 */
    background: #f5f5f5;
  }
  
  .delivery-status-title {
    margin: 0 0 8px;
    font-weight: bold;
    font-size: 1.1rem;
  }
  
  /* 当 isPending 为 false 时，显示预期送达信息 */
  .delivery-status-expected {
    display: flex;
    align-items: baseline;
    gap: 10px;
    margin-bottom: 8px;
  }
  
  .delivery-status-date {
    font-size: 1.4rem;
    font-weight: 600;
  }
  
  .delivery-status-time {
    font-size: 1rem;
    color: #666;
  }
  
  /* 当 isPending 为 true 时，显示的文字 */
  .delivery-status-pending {
    font-size: 1.4rem;
    font-weight: 600;
    color: #777;
    margin-bottom: 8px;
  }
  
  .delivery-status-explanation {
    margin: 8px 0;
    font-size: 0.95rem;
    line-height: 1.4rem;
  }
  
  /* 链接按钮 */
  .delivery-status-link {
    display: inline-block;
    margin-top: 8px;
    text-decoration: underline;
    color: #0066c0;
    font-size: 0.95rem;
    cursor: pointer;
  }
  
  /* 根据 color prop 来切换背景色 */
  .delivery-status-gray {
    background-color: #bbbbbb;
  }
  
  .delivery-status-blue {
    background-color: #b9dbff;
  }
  
  .delivery-status-red {
    background-color: #ffbbbb;
  }
  
  .delivery-status-pink {
    background-color: #ffb8e7;
  }