.title1 {
  padding: 0.3rem;
  padding-bottom: 3rem;
  text-align: center;
  background-color: #e4f9ff;
  font-family: 'Circular Std', sans-serif;
}

/* For PC */
.centered-content {
  max-width: 900px; /* Limit the width */
  margin: 0 auto; /* Center the content */
}

#title1-pc h2 {
  font-size: 3.75rem;
  margin-bottom: 5px;
  font-weight: 900;
  color: #333;
}

#title1-mobile h2 {
  font-size: 2.4rem;
  margin-bottom: 5px;
  font-weight: 900;
  color: #333;
}

#title2-pc h2 {
  font-size: 1.44rem;
  margin-bottom: 20px;
  font-weight: 400;
  color: #333;
}

#title2-mobile h2 {
  font-size: 1.2rem;
  margin-bottom: 20px;
  font-weight: 400;
  color: #333;
}

.title3-button {
  padding: 0 20px; /* 去掉上下内边距，保持高度一致 */
  font-size: 18px;
  font-weight: 800;
  background-color: #9350ea; /* 鼠标悬停时填充色 */
  border: 3px solid #bf93f8; /* 鼠标悬停时边框色 */
  color: #E6E6FA; /* 鼠标悬停时文本颜色 */
  border-radius: 40px; /* 圆角矩形 */
  cursor: pointer;
  transition: all 0.3s ease;
  height: 50px; /* 与输入框高度一致 */
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title3-button:hover {
  background-color: #7301a3; /* 鼠标悬停时填充色 */
  border: 3px solid #bf93f8; /* 鼠标悬停时边框色 */
  color: #E6E6FA; /* 鼠标悬停时文本颜色 */
}