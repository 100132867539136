/* 使用 Circular Std 字体 */
* {
    font-family: 'Circular Std', sans-serif;
  }
  
  /* 整体布局 */
  .dashboard-container {
    display: flex;
    height: 100vh;
    background-color: #f9fafc;
  }
  
  /* 左侧功能栏 */
  .sidebar {
    width: 250px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .sidebar-item {
    margin-bottom: 1.5rem;
  }
  
  .sidebar-item h3 {
    font-size: 1.2rem;
    font-weight: 500;
    color: #333;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .sidebar-item h3:hover {
    color: #007bff;
  }
  
  .external-link-arrow {
    font-size: 1rem;
    margin-left: 5px;
  }
  
  /* 右侧内容区域 */
  .main-content {
    flex-grow: 1;
    padding: 2rem;
    background-color: #f4f7fc;
  }
  
  .content-placeholder {
    text-align: center;
    margin-top: 100px;
  }
  
  .content-placeholder h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .content-placeholder p {
    font-size: 1.2rem;
    color: #777;
  }
  
  /* 移动端布局 */
  @media (max-width: 768px) {
    .dashboard-container {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%;
      padding: 1rem;
    }
  
    .main-content {
      padding: 1rem;
    }
  }