/* 邮件页面的容器 */
.mails-page-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding: 2rem;
    justify-content: center;
    background-color: #f4f7fc;
  }
  
  /* 邮件卡片 */
  .mail-card {
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    width: 450px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    transition: box-shadow 0.3s ease;
  }
  
  .mail-card:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  /* 邮件详细信息 */
  .mail-details h3 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .tracking-info {
    display: flex;
    align-items: center;
  }
  
  .tracking-info p {
    font-size: 0.9rem;
    color: #555;
    margin-right: 0.5rem;
  }
  
  .mail-details p {
    font-size: 0.9rem;
    color: #777;
    margin-bottom: 0.3rem;
  }
  
  /* 左上角的类型图标 */
  .mail-icon {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .mail-icon.domestic {
    background-color: #ff4081;
  }
  
  .mail-icon.international {
    background-color: #42a5f5;
  }
  
  /* 右侧的邮件状态图标 */
  .mail-status {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    font-weight: bold;
  }
  
  .mail-status.in-transit {
    background-color: #ffb74d;
    color: #fff;
  }
  
  .mail-status.delivered {
    background-color: #66bb6a;
    color: #fff;
  }
  
  .mail-status.created {
    background-color: #90a4ae;
    color: #fff;
  }
  
  /* 控制菜单图标 */
  .menu-button {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .menu-button:hover {
    color: #007bff;
  }
  
  /* 响应式布局 */
  @media (max-width: 768px) {
    .mail-card {
      width: 90%;
      flex-direction: column;
      align-items: flex-start;
    }
  }