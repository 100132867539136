/* src/index.css */


  @font-face {
    font-family: 'Circular Std';
    src: url('./assets/fonts/circular-std-medium-500.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  body {
    font-family: 'Circular Std', sans-serif;
    background-color: #ffffff; /* 确保全局背景颜色为白色 */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }