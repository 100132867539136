/* src/styles/TrackingSection.css */

.tracking-section {
  padding: 40px 20px; /* 增加内边距以提升布局 */
  text-align: center;
  background-color: #f8f9fa;
  font-family: 'Circular Std', sans-serif; /* 应用 Circular Std 字体 */
}

.tracking-section h2 {
  font-size: 32px;
  margin-bottom: 30px;
  font-weight: 500; /* Medium 500 */
  color: #333; /* 深色字体，提升可读性 */
}

.tracking-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* 允许在小屏幕上换行 */
  gap: 10px; /* 增加间距 */
}

.tracking-input {
  width: 300px;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 16px; /* 圆角矩形 */
  outline: none;
  transition: border 0.3s ease;
  box-sizing: border-box; /* 确保填充不会增加总宽度 */
}

.tracking-input:focus {
  border-color: #9400D3; /* 聚焦时边框颜色 */
}

.tracking-button {
  padding: 0 20px; /* 去掉上下内边距，保持高度一致 */
  font-size: 16px;
  background-color: #E6E6FA; /* 非常浅的紫罗兰色 */
  border: 1px solid #9400D3; /* 深紫罗兰色 */
  color: #9400D3;
  border-radius: 16px; /* 圆角矩形 */
  cursor: pointer;
  transition: all 0.3s ease;
  height: 40px; /* 与输入框高度一致 */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px; /* 确保按钮宽度 */
}

.tracking-button:hover {
  background-color: #9400D3; /* 鼠标悬停时填充色 */
  border: 1px solid #E6E6FA; /* 鼠标悬停时边框色 */
  color: #E6E6FA; /* 鼠标悬停时文本颜色 */
}