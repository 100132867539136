/* src/components/Track.css */

/* 通用页面样式 */
.track-page {
  font-family: 'Arial', sans-serif;
  margin: 20px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* 搜索框样式 */
.search-form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-form input {
  width: 300px;
  padding: 10px;
  border-radius: 25px;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.search-form button {
  padding: 10px 20px;
  background-color: #9e2670;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
}

.search-form button:hover {
  background-color: #4c0b45;
}

/* 邮件详情样式 */
.mail-info {
  padding: 20px;
  margin-bottom: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.details, .summary {
  font-size: 16px;
  line-height: 1.6;
}

/* 时间轴样式 */
.timeline {
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.timeline ul {
  list-style-type: none;
  padding: 0;
}

.timeline li {
  margin-bottom: 20px;
  position: relative;
  padding-left: 40px;
}

.timeline li:before {
  content: '';
  width: 10px;
  height: 10px;
  background: #cd44af;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 10px;
}

.timeline-content {
  background: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.timeline-date {
  font-weight: bold;
  color: #333;
}
