/* src/components/ChooseBarcodeOption/ChooseBarcodeOption.css */

.choose-barcode-option {
    text-align: center;
    padding: 40px;
  }
  
  .options {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
  }
  
  .option-card {
    width: 300px;
    border-radius: 10px;
  }
  
  .option-card h2 {
    color: #eb2f96;
  }
  /* 整体页面样式 */
.choose-barcode-option {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Circular Std', sans-serif;
  color: #333;
}

.tracking-title {
  text-align: left;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

/* 选项卡样式 */
.options {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

.option-card {
  flex: 1 1 300px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
  background-color: #fff;
}

.option-card h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.option-card p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
}

/* 信封预览样式 */
.envelope-preview {
  margin-bottom: 40px;
}

.envelope-preview h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: left;
}

.envelope {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 300px;
  margin: 0 auto;
  background: #fff;
  border: 2px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.envelope::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: #f7f9fc;
  clip-path: polygon(0 0, 50% 100%, 100% 0);
}

.sender {
  position: absolute;
  top: 90px;
  left: 20px;
  font-size: 1rem;
  line-height: 1.5;
}

.recipient {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 1rem;
  text-align: right;
  line-height: 1.5;
}

/* 步骤区域标题 */
.steps-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: left;
}

/* 移动端步骤卡片 */
.step-card-mobile {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  margin: 1rem 0;
  text-align: left;
}

.step-card-mobile h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.step-image-mobile {
  width: 100%;
  margin: 1rem 0;
}

/* PC端步骤卡片 */
.step-card-pc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
  padding: 2rem;
  margin: 2rem 0;
}

.step-card-pc .step-content {
  width: 55%;
}

.step-card-pc h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.step-card-pc p {
  font-size: 1.2rem;
  color: #444;
}

.step-image-left,
.step-image-right {
  width: 40%;
  max-width: 250px;
}

.step-image-left {
  order: 1;
}

.step-image-right {
  order: 2;
}

@media (max-width: 600px) {
  .step-card-pc {
    flex-direction: column;
  }
  .step-card-pc .step-content,
  .step-image-left,
  .step-image-right {
    width: 100%;
    max-width: 100%;
  }
  .step-image-left,
  .step-image-right {
    margin: 1rem 0;
  }
}