/* styles/Main.css */
/* 主视觉区域 */
.banner {
    position: relative;
    text-align: center;
    color: rgb(105, 11, 11);
  }
  
  .banner-image {
    width: 100%;
    height: auto;
  }
  
  .banner-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
  }
  
  /* 核心功能展示 */
  .features {
    display: flex;
    justify-content: space-around;
    padding: 32px 0;
  }
  
  .feature {
    text-align: center;
    width: 45%;
  }
  
  .feature img {
    width: 100px;
    height: 100px;
  }
  
  .feature-button {
    margin-top: 16px;
    padding: 8px 16px;
    background-color: #B227BB;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: medium;
  }
  
  .feature-button:hover {
    background-color: #871196;
  }
  
  /* 快速查询入口 */
  .tracking-section {
    text-align: center;
    padding: 32px 0;
    background-color: #f8f9fa;
  }
  
  .tracking-form {
    margin-top: 16px;
  }
  
  .tracking-input {
    width: 300px;
    padding: 8px;
  }
  
  .tracking-button {
    padding: 8px 16px;
    margin-left: 8px;
    background-color: #1757b8;
    border: none;
    color: #fff;
    cursor: pointer;
  }
  
  .tracking-button:hover {
    background-color: #117a8b;
  }
  
  /* styles/Main.css */

/* 手机端样式调整 */
@media (max-width: 768px) {
    .features {
      flex-direction: column;
      align-items: center;
    }
  
    .feature {
      width: 80%;
      margin-bottom: 32px;
    }
  
    .banner-title {
      font-size: 32px;
    }
  
    .tracking-input {
      width: 80%;
    }
  }
  