/* src/styles/SendLetter.css */
.send-letter {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .description {
    margin-bottom: 30px;
  }
  
  .description h1 {
    margin-bottom: 10px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .form-row .form-group {
    flex: 1;
  }
  
  .error {
    color: red;
    font-size: 12px;
  }
  
  button[type="submit"],
  button[type="button"] {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-right: 10px;
  }
  
  button[type="submit"]:hover,
  button[type="button"]:hover {
    background-color: #0056b3;
  }
  
  /* 响应式设计 */
  @media (max-width: 768px) {
    .form-row {
      flex-direction: column;
    }
  }
  