.pre-send-title1 {
  padding: 1rem;
  text-align: center;
  background-color: #f7f9fc;
  font-family: 'Circular Std', sans-serif;
}

.header-section-pc h2 {
  font-size: 3.75rem;
  font-weight: 900;
  color: #333;
  margin-bottom: 2rem;
}

.header-section-mobile h2 {
  font-size: 2.4rem;
  font-weight: 900;
  color: #333;
  margin-bottom: 2rem;
}

/* Mobile Styles */
.step-card-mobile {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  margin: 1rem auto;
  max-width: 500px;
  text-align: left;
}

.step-image-mobile {
  width: 100%;
  margin: 1rem 0;
}

.step-card-mobile h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.step-card-mobile p {
  font-size: 1rem;
  color: #555;
}

/* PC Styles */
.step-card-pc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
  padding: 2rem;
  margin: 2rem auto;
  max-width: 900px;
  text-align: left;
}

.step-content {
  width: 50%;
}

.step-image-left {
  width: 40%;
  order: 1;
}

.step-image-right {
  width: 40%;
  order: 2;
}

.step-card-pc h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.step-card-pc p {
  font-size: 1.2rem;
  color: #444;
}

/* Button Styles */
.send-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin: 1rem auto;
  display: block;
  max-width: 200px;
}

.send-button:hover {
  background-color: #45a049;
}